import React, { Component } from 'react';
import './App.css';
import Home from "./components/Home";
import logo from './Images/logo 2.jpg';
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

class App extends Component {

render() {

  function resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
    shrinkOn = 200,
    headerEl = document.getElementById('header');
    
    if (distanceY > shrinkOn) {
      headerEl.classList.add("smaller");
    } else {
      headerEl.classList.remove("smaller");
    }
  }
  
  window.addEventListener('scroll', resizeHeaderOnScroll);
 
  return (
    <Router>
      <div>
        <div id="header">
            <img src={logo} id="logo" alt="logo" />
        </div>
        <Switch>
        <Route path="/" exact component={Home} />
        <Route render={() => <Redirect to="/"/>}/>
        </Switch>
      </div>
    </Router>
  );
}
}

export default App;
