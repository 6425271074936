import React from "react";
import '../css/Home.css';
import Gallery from "react-photo-gallery";
import SmartSlider from "react-smart-slider";
import Image1 from '../Images/facade.png';
import Image2 from '../Images/IMG_2187.jpg';
import Image3 from '../Images/IMG_2189.jpg';
import Image4 from '../Images/IMG_2190.jpg';
import Image5 from '../Images/IMG_2191.jpg';
import Image6 from '../Images/IMG_2193.jpg';
import Image7 from '../Images/IMG_2194.jpg';
import Image8 from '../Images/IMG_2195.jpg';
import Image9 from '../Images/IMG_2196.jpg';
import Image10 from '../Images/IMG_2197.jpg';
import Image11 from '../Images/IMG_2198.jpg';

class Home extends React.Component {

    render() {

        var now = new Date();
        var year = now.getFullYear();

        const photos = [
            {
              src: Image1,
              width: 4,
              height: 3
            },
            {
              src: Image2,
              width: 5,
              height: 6
            },
            {
                src: Image3,
                width: 1,
                height: 1
            },
              {
                  src: Image6,
                  width: 1,
                  height: 1
              },
              {
                src: Image7,
                width: 4,
                height: 3
              },
              {
                src: Image8,
                width: 1,
                height: 1
              },
              {
                  src: Image9,
                  width: 1,
                  height: 1
              },
              {
                src: Image11,
                width: 1,
                height: 1
              }

          ];

          const slidesArray = [
            {
                url: Image1
              },
              {
                url: Image2
              },
              {
                  url: Image3
              },
                {
                    url: Image6
                },
                {
                  url: Image7
                },
                {
                  url: Image8
                },
                {
                    url: Image9
                },
                {
                  url: Image11
                }
          ];

        return (
            <div className="home">
                <div class="bandeauImage"></div>
                <div class="galleryContainer">
                    <h1 class="text_dark">
                        <span class="gallery_title">
                            Angelo Cino <br></br>
                            <b>Coiffeur barbier perruquier</b>
                        </span>
                    </h1>
                    <div class="divider"></div>
                    <div class="description">
                        <p>
                            Le salon de coiffure Angelo Cino, situé sur la Place Communale de La Louvière, accueille les dames, hommes et enfants.<br></br>
                            Du mardi au samedi, avec ou sans rendez-vous.
                        </p>
                    </div>
                    <div className="galleryDesktop">
                        <Gallery photos={photos} />
                    </div>
                    <div className="galleryMobile">
                        <SmartSlider slides={slidesArray} autoSlide={true} autoSlideInterval={6000}/>
                    </div>
                </div>
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe title="map" width="1898" height="375" id="gmap_canvas" src="https://maps.google.com/maps?q=angelo%20cino%20coiffure&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                </div>
                <div id="Footer">
                    <h2 id="companyName" >Angelo Cino coiffeur</h2>
                    
                    <div id="Colonnes">
                            <span id="tel" class="alignleft">
                                <p id="fixe"><i class="fa fa-phone fa-lg"></i><a href="tel:+32-64-21-10-35"> &nbsp;+32 64 21 10 35</a></p>
                                <p id="gsm"><i className="material-icons">phone_iphone</i><a href="tel:+32-496-15-69-17" > +32 496 15 69 17</a></p>
                            </span>          
                            <span id="map" class="alignright">
                                <i className="material-icons">place</i> Place Communale 5 - 7100 La Louvière
                            </span>
                    </div>
                    
                    <div className="social_link">
                        <a href="https://www.facebook.com/angelo.cino.coiffeur" title="facebook">
                            <i class="fa fa-facebook fa-lg"></i>
                        </a>
                    </div>
                    <div className="Footer_copyright">
                        <p id="copy" className="Footertext">&copy; {year} Coiffeur Angelo Cino, tous droits réservés.</p>
                    </div>
                </div>
            </div>
            );
        }
    }
    
    export default Home;